import { onMounted,ref } from "vue"
import $api from "@/api"

/**
 * @description
 * @author yuk_yao
 * @date 2021-09-02
 * @export
 * @param {String} type 1:行业资讯 2:政策文库 3:招投标 15:企业平台
 * @returns {*} 
 */
export default function useInfoTree(type){
    const classifies = ref([{
        label: "最新",
        value: '-1',
        active: true
    }])
    const getInfoTree=async ()=>{
        const [err, data] = await $api.article.getAllInfoTree();
        if (!data.succeeded) return;
        data.data.forEach(e => {
            e.value === type ? classifies.value = classifies.value.concat(e.children) : ""
        })
    }
    onMounted(getInfoTree)
    return {
        classifies
    }
}