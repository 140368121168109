<template>
    <main class="ztb_main policy_main info_details author_list">
      <div class="ztb_box">
        <class-items :classifies="classifies" @callback="getNewsById"></class-items>
        <ul class="pl_list article-list news-list">
          <li v-for="item in news.list" :key="item.id" @click="showDetail(item.id)">
            <a href="javascript:void(0)" class="db w100p item">
              <div class="img_box">
                <img :src="getImgUrl(item.image)"  @error="errPic($event)" class="db">
              </div>
              <div class="brief w100p">
                <div class="title">{{item.title}}</div>
                <div class="intro">{{item.descrption}}</div>
                <div class="date_price cf">
                  <div class="date fl" v-formatTime>{{item.createdTime}}</div>
                </div>
              </div>
            </a>
          </li>
        </ul>
        <div class="pagnation">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryParams.PageIndex"
            :page-sizes="[5,10,15,20]"
            :page-size="queryParams.PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="news.totalCount">
          </el-pagination>
        </div>
      </div>
      <div class="ztb_latest">
        <div class="side_box">
        <div class="title">
         <span>热文作者</span>
        </div>
        <ul class="authors article-list">
          <li v-for="item in topAuthors" :key="item.author">
            <a href="javascript:void(0);" class="auto cf" @click="showAuthorInfo(item.author)">
              <div class="img_box fl">
                <img :src="getImgUrl(item.image)"  @error="errPic($event,'avatar')">
              </div>
              <div class="name_intro fr">
                <div class="name">{{item.nickName}}</div>
                <span class="otw">{{item.introduction}}</span>
              </div>
            </a>
          </li>
        </ul>
        </div>
  

        <div class="sidebar_box">
        <div class="title">
         <span>热文排行</span>
        </div>
        <ul>
          <li v-for="(item,index) in topArticles" @click="showDetail(item.id)" :key="item">
            <a class="cup">
              <i>{{index+1}}</i>
              <img v-show="index===0" :src="getImgUrl(item.image)" @error="errPic($event)" class="w100p">
              <p>{{item.title}}</p>
            </a>
          </li>
        </ul>
        </div>


      </div>
    </main>
</template>
<script setup>
import {useRouter} from "vue-router"
import {ElInput,ElPagination} from "element-plus"
import ClassItems from "@/components/classItems"
import useInfoTree from "@/composable/universal/useInfoTree"
import usePagination from "@/composable/universal/usePagination"
import useAllNews from "@/composable/news/useAllNews"
import useHotAuthor_Article from "@/composable/news/useHotAuthor_Article"
import {getImgUrl,errPic} from "@/utils/imageRelated";

        const router=useRouter()
        const {classifies}=useInfoTree("1")
        const {news,queryParams,getNewsById}=useAllNews(classifies)
        const {handleSizeChange,handleCurrentChange}=usePagination(queryParams,getNewsById)
        const {topAuthors,topArticles}=useHotAuthor_Article()
        const showDetail=(id)=>{
            router.push({
              name:"NewsDetail",
              query:{id}
            })
        }
        const showAuthorInfo=(id)=>{
          router.push({
            name:"AuthorDetail",
            query:{id}
          })
        }
</script>
<style lang="less" scoped>
:deep(.search-input>input){
    border-radius: 4ch !important;
}
  .news-search{
        width: 200px;
    margin-top: 15px;
  }
  .pagnation{
    text-align: center;
  }
  .top-article{
    display: flex;
    margin-top: 27px;
    cursor: pointer;
  }
  .top-article-container{
    margin-left: 27px;
    display: flex;
    flex-direction: column;
  }
  .top-article-container>span:nth-child(1){
    font-weight: bold;
  }
  .top-article-container>span:nth-child(2){
    margin-top: 17px;
    flex-grow: 1;
  }
  .top-article>img{
    width: 186px;
    height: 116px;
  }
  .pr-4{
    padding-right: 4px;
  }

  .policy_main .ztb_box .article-list{padding-right:20px;}

// 2021 10 20
  .policy_main .cate span + span{margin-right:0 20px 0 0;}
  .cup{cursor:pointer;}
</style>