import {reactive} from "vue"

/**
 * @description
 * @author yuk_yao
 * @date 2021-09-02
 * @export
 * @param {Object} queryParams
 * @param {Function} fn
 * @returns {*} 
 */
export default function usePagination(queryParams,fn){
    
    const handleSizeChange = (size) => {
        queryParams.PageIndex=1
        queryParams.PageSize=size;
        fn()
    }
    const handleCurrentChange = (current) => {
        queryParams.PageIndex=current;
        fn()
    }
    return {
        handleSizeChange,handleCurrentChange
    }
}