<template>
    <div class="cate w100p">
          <span v-for="item in classifies" :key="item.value" :class="[item.active?'fs-orange':'']" @click="emits('callback',item.value)">{{item.label}}</span>
          <div class="search-isfree">
            <div class="library-search">
            <el-input
              @keydown.enter="emits('callback','',keyWords)"
              class="search-input"
              placeholder="请输入内容"
              v-model="keyWords">
              <template #suffix>
                <i class="el-icon-search" @click="emits('callback','',keyWords)"></i>
              </template>
            </el-input>
          </div>
          <div class="slot">
             <slot ></slot>
          </div>
          </div>
        </div>
</template>
<script setup>
import {ref,defineEmits} from 'vue'
    const props=defineProps({
            classifies:{
                type:Array,
                default:[]
            }
        })

    const emits=defineEmits(['callback'])

    const keyWords=ref("")

</script>
<style lang="less" scoped>
:deep(.search-input>input){
    border-radius: 4ch !important;
}
  .library-search{
        width: 200px;
    margin-top: 15px;
  }
  .search-isfree{
    display: flex;
    align-items: baseline;
  }
  .slot{
    flex-grow: 1;
    text-align: right;
  }
  .el-icon-search{
    padding-right: 8px;
  }
</style>
