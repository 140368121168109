import { onMounted, ref } from "vue"
import $api from "@/api"
export default function useHotAuthor_Article(){
    const topAuthors=ref([])
    const topArticles=ref([])
    const getAuthors=async()=>{
        const [err,data]=await $api.article.getHotAuthor();
        if(!data.succeeded) return;
        topAuthors.value=data.data
    }
    const getArticles=async()=>{
        const [err,data]=await $api.article.getHotArticle();
        if(!data.succeeded) return;
        topArticles.value=data.data
    }
    onMounted(()=>{
        getAuthors()
        getArticles()
    })
    return {
        topArticles,
        topAuthors
    }
}