import {
    onMounted,
    reactive
} from "vue"
import {useRoute} from "vue-router"
import $api from "@/api"
export default function useAllNews(classItems) {
    const route=useRoute()
    const news = reactive({
        list:[],
        totalCount:0
    })

    //#region 按条件获取资讯
    const queryParams = reactive({
        Title: "",
        TypeId: '-1',
        PageIndex: 1,
        PageSize:15
    })
    const getNewsById = async (id = "",keywords) => {
        if (!!id) {
            queryParams.TypeId = id
            classItems.value.map(e => {
                e.value === id ? e.active = true : e.active = false;
                return e
            })
        }
        queryParams.Title=keywords
        const [err, data] = await $api.article.getArticleList(Object.assign(queryParams))
        if (!data.succeeded) return;
        news.list = data.data.items.sort((a,b)=>new Date(b.createdTime)-new Date(a.createdTime))
        news.totalCount=data.data.totalCount
    }
    //#endregion


    //#endregion
    onMounted(()=>{
        getNewsById("",route.query?.keywords??"")
    })
    return {
        news,
        queryParams,
        getNewsById,
    }
}